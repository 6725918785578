@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap');
@import "./variables";
body {
    margin: 0;
    background-color: $background-color;
}

*,
.MuiTypography-root,
.MuiListItemText-primary,
.MuiTypography-body1 {
    font-family: 'Montserrat', sans-serif;
}

#react-simple-modal-container {
    div {
        z-index: 10000;
    }
  .modal__main {
    min-height: auto !important;

    .modal-button-container {
        width: 100%;
        margin-top: 15px;
        text-align: center;
        .modal-button {
            margin: auto;
        }

    }
    .rsm-flex-1 {
      padding: 0;
    }
  }
}

.fields-group {
    display: grid;
    align-items: center;
    justify-content: space-between;
    align-content: center;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    margin-bottom: 50px;
    margin-top: 40px;

    &--cols-2 {
        grid-template-columns: repeat(2, 1fr);
    }
    &--rows-2 {
        grid-template-rows: repeat(2, 1fr);
    }
}

.modal-buttons__main {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
    align-content: center;
    flex-wrap: nowrap;
    gap: 20px;
    margin-right: 20px;
    margin-top: 40px;

    .MuiContainer-root {
        width: 125px;
        margin: 0;

        .btn {
            margin-bottom: 0;
        }
    }
}

.MuiAutocomplete-endAdornment {
    top: 0;
    .MuiButtonBase-root {
        padding: 2px;
    }
}

.searchbar__button {
    cursor: pointer;
}

.row-actions__main > svg {
    cursor: pointer;
}

.selected-actions__main {
    .MuiInputBase-root.MuiOutlinedInput-root {
        height: 40px;
    }
}

.selected-actions__main {
    height: 59px;
}

.user-form__main {
    .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd {
        height: 36px;
    }
}

.rsm-fixed.modal-provider__main {
    //z-index: 39;
}

.form__view__user {
    .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.Mui-disabled.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd{
        height: 36px;
    }
}

.form__view__user {
    .MuiSelect-select.MuiSelect-outlined.MuiSelect-multiple.MuiInputBase-input.MuiOutlinedInput-input{
        height: 36px;
    }
}

.filter-menu {
    overflow-y: auto !important;
    max-height: 60vh !important;
}

.filter-footer {
    position: sticky;
    bottom: 0;
    padding: 10px;
    //z-index: 10;
    background-color: #FBFBFB;
    border-top: 1px solid rgba(0, 170, 128, 0.1);
    height: 50px;
}

.field-item--full-width {
    grid-column: span 2;
}
